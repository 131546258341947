import React from 'react';

export enum AlertVariants {
  warning = 'warning',
  error = 'error',
}

const AlertMessage: React.FC<React.PropsWithChildren<{ variant: string; className?: string }>> = ({
  children,
  variant,
  className,
  ...rest
}) => {
  const bgVariant: Partial<{ [variant: string]: string }> = {
    [AlertVariants.warning]: 'bg-sand-30',
    [AlertVariants.error]: 'bg-red-200',
  };

  const textVariant: Partial<{ [variant: string]: string }> = {
    [AlertVariants.warning]: 'text-gray-900',
    [AlertVariants.error]: 'text-red-800',
  };

  return (
    <div
      className={`${bgVariant[variant]} ${textVariant[variant]} autoType300 p-5 mb-4 md:px-8 md:py-6 md:mb-6 rounded-md ${className}`}
      {...rest}>
      {children}
    </div>
  );
};

export default AlertMessage;
