import React from 'react';
import { FormattedMessage } from 'react-intl';

import AlertMessage, { AlertVariants } from './AlertMessage';

const VehicleNoResultsMessage: React.FC<{
  hasNearby: boolean;
}> = ({ hasNearby }) => (
  <AlertMessage variant={AlertVariants.warning}>
    <p className="inline md:block" data-testid="no-listings-error">
      <FormattedMessage
        defaultMessage="Hmm... We don’t have anything available that matches your criteria."
        id="ubYzUb"
        description="Message for no search results"
      />
    </p>
    <p className="inline ml-1 md:block md:ml-0">
      {hasNearby ? (
        <FormattedMessage
          defaultMessage="Check out our local recommendations below."
          id="fuc8ZE"
          description="Message for no search results, with nearby listings"
        />
      ) : (
        <FormattedMessage
          defaultMessage="Try adjusting your search by changing your location."
          id="irESxU"
          description="Message for no search results, without nearby listings"
        />
      )}
    </p>
  </AlertMessage>
);

export default VehicleNoResultsMessage;
