import React from 'react';
import { useIntl } from 'react-intl';

import { useBreakpoint } from '@/hooks/useBreakpoint';

interface IResultsCountProps {
  total: number;
  isStay?: boolean;
  isCampground?: boolean;
}
export const ResultsCount: React.FC<IResultsCountProps> = ({
  total,
  isStay = false,
  isCampground = false,
}) => {
  const { isMobile } = useBreakpoint();
  const intl = useIntl();

  const desktopClassName = `autoType200 font-text text-gray-50 font-medium ${
    total === 0 && 'text-gray-500'
  }`;

  const mobileClassName = `text-sm text-gray-50 semiHeighlight`;

  // We don't need to show "0 listing found" since we have empty states.
  if (!total) return null;

  const mainStays = intl.formatMessage(
    {
      defaultMessage:
        '{total, plural, =1 {# stay found} =1000 {#+ stays found} other {# stays found}}',
      id: 'EF5Q3U',
      description: 'Results Count: Search results total count text',
    },
    {
      total,
    },
  );

  const mainCampgrounds = intl.formatMessage(
    {
      defaultMessage:
        '{total, plural, =1 {# campground found} =1000 {#+ campgrounds found} other {# campgrounds found}}',
      id: 'LXqjS2',
      description: 'Results Count: Search results total count text',
    },
    {
      total,
    },
  );

  const mainVehicles = intl.formatMessage(
    {
      defaultMessage:
        '{total, plural, =1 {# vehicle found} =1000 {#+ vehicles found} other {# vehicles found}}',
      id: 'z3VYef',
      description: 'Results Count: Search results total count text',
    },
    {
      total,
    },
  );

  const countCopy = isStay ? mainStays : isCampground ? mainCampgrounds : mainVehicles;

  return (
    <div className={isMobile ? mobileClassName : desktopClassName} data-testid="search-results">
      {countCopy}
    </div>
  );
};

interface INearbyResultsCountProps {
  total: number;
}
// We only render similar listings for RVs right now due to low inventory issues.
export const NearbyResultsCount: React.FC<INearbyResultsCountProps> = ({ total }) => {
  const { isMobile } = useBreakpoint();
  const intl = useIntl();

  const desktopClassName = `autoType200 font-text text-gray-50 font-medium ${
    total === 0 && 'text-gray-500'
  }`;

  const mobileClassName = `text-sm text-gray-50 semiHeighlight`;

  // We don't need to show "0 listing found" since we have empty states.
  if (!total) return null;

  const similarVehicles = intl.formatMessage(
    {
      defaultMessage:
        '{total, plural, =1 {# similar vehicle found} =1000 {#+ similar vehicles found} other {# similar vehicles found}}',
      id: 'rFwtIP',
      description: 'Results Count: Search results total count text',
    },
    {
      total,
    },
  );

  return (
    <div className={isMobile ? mobileClassName : desktopClassName} data-testid="search-results">
      {similarVehicles}
    </div>
  );
};
