import { EProjectSection, trackEventV2 } from '../trackEventV2';
import {
  EMapEventName,
  IBaseMapUpdatedEvent,
  IListingMarkerPreviewedEvent,
  IMapEventInfo,
  IMapViewExpandedEvent,
} from './types';
export const trackMapToggleEvent = (event: IMapEventInfo) => {
  trackEventV2(EMapEventName.MAP_VIEW_TOGGLED, EProjectSection.MARKETPLACE, {
    ...event,
  });
};

export const trackListingMarkerPreviewedEvent = (event: IListingMarkerPreviewedEvent) => {
  trackEventV2(EMapEventName.LISTING_MARKER_PREVIEWED, EProjectSection.MARKETPLACE, {
    ...event,
  });
};

export const trackSearchAsMapMoveEvent = (event: IMapEventInfo) => {
  trackEventV2(EMapEventName.SEARCH_AS_MAP_MOVE_TOGGLED, EProjectSection.MARKETPLACE, {
    ...event,
  });
};

export const trackSearchMapExpandedEvent = (event: IMapViewExpandedEvent) => {
  trackEventV2(EMapEventName.SEARCH_MAP_EXPANDED, EProjectSection.MARKETPLACE, {
    ...event,
  });
};

export const trackBaseMapUpdatedEvent = (event: IBaseMapUpdatedEvent) => {
  trackEventV2(EMapEventName.BASE_MAP_UPDATED, EProjectSection.MARKETPLACE, {
    ...event,
  });
};
