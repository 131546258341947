import React from 'react';
import { useIntl } from 'react-intl';

import Button, { ButtonVariants } from '@/components/switchback/Button/Button';

import AlertMessage, { AlertVariants } from './AlertMessage';

type TOwnerResultsMessageProps = {
  ownerName: string;
  onOwnerButtonClick: () => void;
  isStay?: boolean;
};
const OwnerResultsMessage: React.FC<TOwnerResultsMessageProps> = ({
  onOwnerButtonClick,
  ownerName,
  isStay,
}) => {
  const intl = useIntl();

  return (
    <AlertMessage
      variant={AlertVariants.warning}
      className="md:flex md:items-center md:justify-between"
      data-testid="banner-dealership-list">
      <p className="md:block">
        {intl.formatMessage(
          {
            defaultMessage: 'You are currently only viewing listings for {ownerName}. ',
            id: 'gGevGO',
            description: 'SearchResults - Message for dealer results',
          },
          { ownerName },
        )}
      </p>
      <Button
        className="mt-4 md:ml-20 md:mt-0"
        variant={ButtonVariants.primaryOutlined}
        onClick={onOwnerButtonClick}
        data-testid="show-all-rvs">
        {intl.formatMessage(
          {
            defaultMessage: 'Show all {isStay, select, true {stays} other {RVs}}',
            id: 'WCTEId',
            description: 'SearchResults - Message for the reset button on dealer results message',
          },
          { isStay: !!isStay },
        )}
      </Button>
    </AlertMessage>
  );
};

export default OwnerResultsMessage;
