import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { GuidedSearchBanner } from '@/components/guided-search';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { trackPromoBannerClicked, trackPromoBannerViewed } from '@/services/analytics/promo';

const PromoBanner: React.FC = () => {
  const intl = useIntl();
  const { isMobile } = useBreakpoint();

  const bannerText = intl.formatMessage({
    defaultMessage: 'Narrow results to find your perfect ride.',
    id: 'YZwMYv',
    description: 'Promo banner - Banner text',
  });
  const bannerCtaText = intl.formatMessage({
    defaultMessage: 'Click Here',
    id: 'zGlx7A',
    description: 'Promo banner - Cta text',
  });

  useEffect(() => {
    if (isMobile) {
      trackPromoBannerViewed({
        bannerText: bannerText,
        bannerCTA: bannerCtaText,
        bannerName: 'guided-search',
        source: 'search',
      });
    }
  }, [bannerCtaText, bannerText, isMobile]);

  const bannerClicked = () => {
    trackPromoBannerClicked({
      bannerText: bannerText,
      bannerCTA: bannerCtaText,
      bannerName: 'guided-search',
      source: 'search',
    });
  };

  if (!isMobile) return null;

  return (
    <GuidedSearchBanner
      bannerText={bannerText}
      bannerCtaText={bannerCtaText}
      bannerStyle={{
        backgroundColor: '#212831',
      }}
      bannerTextStyle={{
        color: '#FFFFFF',
      }}
      bannerCtaStyle={{
        color: '#FFFFFF',
      }}
      onClick={bannerClicked}
    />
  );
};

export default PromoBanner;
