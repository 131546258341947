import { ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import Head from 'next/head';
import qs from 'querystring';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import NonDeterministicPagination, {
  ENonDeterministicPaginationActions,
} from '@/components/ui/NonDeterministicPagination/NonDeterministicPagination';
import Pagination from '@/components/ui/Pagination/Pagination';
import { getItemsPerPage } from '@/constants/pagination';
import { ESearchFilters } from '@/constants/searchFilters';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useRouter } from '@/hooks/useRouter';
import { applySearchFilter } from '@/redux/modules/search';
import { lastPaginationDelta } from '@/redux/selectors/analytics-selectors/search/searchViewedEventData';
import { getQueryParams } from '@/redux/selectors/queryParams';
import { getSearchResultsMeta } from '@/redux/selectors/search/meta';
import {
  getIsLoading,
  getSearchResults as getSearchResultsSelector,
} from '@/redux/selectors/search/searchResults';
import { trackEvent } from '@/services/track-event';
import { clamp } from '@/utility/numbers';

const PaginationContainer: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { total, startPosition, stopPosition, drivableDistanceChecked } =
    useSelector(getSearchResultsMeta);

  const { [ESearchFilters.PAGE_LIMIT]: pageLimit, [ESearchFilters.PAGE_OFFSET]: pageOffset } =
    useSelector(getQueryParams);
  const isSearchLoading = useSelector(getIsLoading);
  const { nearby } = useSelector(getSearchResultsSelector);

  const itemsPerPage = getItemsPerPage(pageLimit);
  const router = useRouter();

  const [
    offsetQueueStorage,
    { update: updateOffsetQueueStorage, remove: removeOffsetQueueStorage },
  ] = useLocalStorage('offsetQueue');

  // Normally having a prop as a state is bad practice.
  // In this case, it acts as a local holder of pagination data
  // so the app appears responsive while it fetches new data.
  // The illusion of responsiveness.
  const [localStop, setLocalStop] = useState(stopPosition || itemsPerPage);
  const [localStart, setLocalStart] = useState(startPosition || 1);

  const totalPages = Math.ceil(total / itemsPerPage);
  const currentPage = Math.ceil(localStop / itemsPerPage);

  // Handle reset from the store and override current "local" page selection.
  useEffect(() => {
    startPosition === 1 && setLocalStart(startPosition);
    stopPosition <= itemsPerPage && setLocalStop(stopPosition);
  }, [itemsPerPage, startPosition, stopPosition]);

  // Handle if the page is loaded by url which already contains offset
  useEffect(() => {
    if (localStart === 1 && startPosition > 1 && localStop === 0 && stopPosition > itemsPerPage) {
      setLocalStart(startPosition);
      setLocalStop(stopPosition);
    }
  }, [itemsPerPage, startPosition, stopPosition, localStop, localStart]);

  useEffect(() => {
    if (
      (drivableDistanceChecked && !pageOffset) ||
      (typeof pageOffset === 'string' && parseInt(pageOffset) === 0)
    ) {
      removeOffsetQueueStorage();
    }
  }, [pageOffset, removeOffsetQueueStorage, drivableDistanceChecked]);

  // This function handles non-deterministic pagination when the user applies the delivery filter.
  // The backend always try returns 24 rentals per page, and the offset will be based on `stopPosition`.
  // The current offset is stored in localStorage to maintain pagination state between requests.
  const handleNonDeterministicPagination = useCallback(
    (variation: ENonDeterministicPaginationActions) => {
      let newOffset: number;
      let newOffsetQueue: number[];
      let offsetQueue: number[] = [0];
      try {
        offsetQueue = typeof offsetQueueStorage === 'string' ? JSON.parse(offsetQueueStorage) : [0];
      } catch {
        removeOffsetQueueStorage();
      }
      const offsetBefore = offsetQueue.at(-1);

      // If there is a mismatch between the query's offset and the localStorage offset queue, reset the offset.
      // This only applies for 'first' and 'prev' actions.
      if (
        ((pageOffset && typeof pageOffset === 'string' && offsetBefore !== parseInt(pageOffset)) ||
          (pageOffset !== '0' && !offsetQueueStorage)) &&
        variation === ENonDeterministicPaginationActions.prev
      ) {
        newOffset = 0;
        newOffsetQueue = [0];
      } else {
        switch (variation) {
          case ENonDeterministicPaginationActions.first:
            newOffset = 0;
            newOffsetQueue = [0];
            break;
          case ENonDeterministicPaginationActions.prev:
            newOffset = offsetQueue[offsetQueue.length - 2] as number;
            newOffsetQueue = offsetQueue.length > 1 ? offsetQueue.slice(0, -1) : [0];
            break;
          case ENonDeterministicPaginationActions.next:
            newOffset = stopPosition;
            newOffsetQueue = [...offsetQueue, newOffset];
            break;
        }
      }

      updateOffsetQueueStorage(JSON.stringify(newOffsetQueue));
      dispatch(
        applySearchFilter({
          [ESearchFilters.PAGE_OFFSET]: String(newOffset),
          [ESearchFilters.PAGE_LIMIT]: String(itemsPerPage),
        }),
      );
      trackEvent({
        event: 'search/page',
        action: 'apply',
        before: { 'page[offset]': offsetBefore },
        after: { 'page[offset]': newOffset },
      });
    },
    [
      offsetQueueStorage,
      pageOffset,
      updateOffsetQueueStorage,
      dispatch,
      itemsPerPage,
      removeOffsetQueueStorage,
      stopPosition,
    ],
  );

  const handlePaginationChange = useCallback(
    (page: number) => {
      // Next page minus currentPage
      lastPaginationDelta(page - currentPage);

      if (!(total && stopPosition)) {
        return;
      }

      const newOffset = (page - 1) * itemsPerPage;
      setLocalStop(clamp(newOffset + itemsPerPage, 1, total));
      setLocalStart(newOffset + 1);

      dispatch(
        applySearchFilter({
          [ESearchFilters.PAGE_OFFSET]: String(newOffset),
          [ESearchFilters.PAGE_LIMIT]: String(itemsPerPage),
        }),
      );
      trackEvent({
        event: 'search/page',
        action: 'apply',
        before: { 'page[offset]': localStart - 1 },
        after: { 'page[offset]': newOffset },
      });

      typeof window === 'object' && window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [itemsPerPage, dispatch, total, stopPosition, localStart, currentPage],
  );

  const [relPrev, relNext] = useMemo(() => {
    let prevUrl, nextUrl;

    const origin =
      (typeof window === 'object' && window.location.origin) || 'https://www.outdoorsy.com';
    const { pathname, query } = router;
    const pageOffset =
      Number(query[ESearchFilters.PAGE_OFFSET]) || Math.max(currentPage - 1, 0) * itemsPerPage;

    if (currentPage > 1) {
      const prevQuery = { ...query, [ESearchFilters.PAGE_OFFSET]: `${pageOffset - itemsPerPage}` };
      prevUrl = `${origin}${pathname}?${qs.stringify(prevQuery)}`;
    }

    if (currentPage < totalPages) {
      const nextQuery = { ...query, [ESearchFilters.PAGE_OFFSET]: `${pageOffset + itemsPerPage}` };
      nextUrl = `${origin}${pathname}?${qs.stringify(nextQuery)}`;
    }

    return [prevUrl, nextUrl];
  }, [router, currentPage, totalPages, itemsPerPage]);

  if (nearby && startPosition === 1) {
    return null;
  }

  if (total && stopPosition) {
    return (
      <>
        <Head>
          {relPrev && <link rel="prev" href={relPrev} data-testid="search-link-rel-prev" />}
          {relNext && <link rel="next" href={relNext} data-testid="search-link-rel-next" />}
        </Head>
        <div className="flex flex-col md:flex-row md:justify-between pb-11 lg:pt-4 lg:pb-12">
          {drivableDistanceChecked ? (
            <NonDeterministicPagination
              className="self-center w-full mb-4 md:mb-0 md:w-auto"
              stopPosition={stopPosition}
              startPosition={startPosition}
              onNonDeterministicPagination={handleNonDeterministicPagination}
              testIdPrefix="search"
              isSearchLoading={isSearchLoading}
              totalItems={total}
            />
          ) : (
            <Pagination
              className="self-center w-full mb-4 md:mb-0 md:w-auto"
              itemsPerPage={itemsPerPage}
              totalItems={total}
              stopPosition={localStop}
              onPaginationChange={handlePaginationChange}
              testIdPrefix="search"
            />
          )}
          {!drivableDistanceChecked && (
            <Text
              variant={ETextStyleVariant.SmallBold}
              className="text-center autoType200 md:flex md:flex-col md:justify-center"
              data-testid="search-results">
              {intl.formatMessage(
                {
                  defaultMessage: '{start}-{stop} of {total} total results',
                  id: 'b5htkF',
                  description: 'Pagination start, stop, and total values.',
                },
                {
                  start: localStart,
                  stop: localStop,
                  total,
                },
              )}
            </Text>
          )}
        </div>
      </>
    );
  }

  return null;
};

export default PaginationContainer;
