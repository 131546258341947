import React from 'react';
import { useIntl } from 'react-intl';

import Button, {
  ButtonShape,
  ButtonSize,
  ButtonVariants,
} from '@/components/switchback/Button/Button';
import { ARROW_RIGHT, ARROW_STOP } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';

import css from './NonDeterministicPagination.module.css';

export const enum ENonDeterministicPaginationActions {
  next,
  prev,
  first,
}

interface INonDeterministicPaginationProps {
  onNonDeterministicPagination: (variation: ENonDeterministicPaginationActions) => void;
  stopPosition: number;
  startPosition: number; // Offset
  testIdPrefix: string;
  totalItems: number;
  isSearchLoading: boolean;
}

interface IButton {
  type: string;
  dataType: 'next' | 'previous' | 'first';
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  arrow_type: 'normal' | 'stop';
  disabled: boolean;
}

type INonDeterministicPaginationElement = React.HTMLAttributes<HTMLDivElement>;

const NonDeterministicPagination: React.FC<
  INonDeterministicPaginationProps & INonDeterministicPaginationElement
> = ({
  onNonDeterministicPagination,
  stopPosition,
  startPosition,
  testIdPrefix,
  totalItems,
  isSearchLoading,
  ...rest
}) => {
  const intl = useIntl();

  const buttonTemplate = ({ type, dataType, handleClick, arrow_type, disabled }: IButton) => {
    return (
      <Button
        aria-label={intl.formatMessage(
          {
            defaultMessage: 'Go to {type} page',
            id: 'R4vmX5',
          },
          {
            type,
          },
        )}
        disabled={disabled}
        className={`mr-4 last:mr-0 ${css.arrow}`}
        onClick={handleClick}
        shape={ButtonShape.circle}
        variant={ButtonVariants.whiteContained}
        size={ButtonSize.small}
        data-testid={`${testIdPrefix}-${dataType}-btn`}>
        <Icon
          data-icon={dataType}
          className={`${css.icon}`}
          name={arrow_type === 'normal' ? ARROW_RIGHT : ARROW_STOP}
          size={IconSize.normal}
        />
      </Button>
    );
  };

  return (
    <nav aria-label="pagination navigation" {...rest}>
      <ul className="flex content-center justify-center md:justify-start gap-1">
        <li className="flex items-center justify-center">
          {buttonTemplate({
            type: intl.formatMessage({ defaultMessage: 'first', id: 'f92A29' }),
            dataType: 'first',
            handleClick: () =>
              onNonDeterministicPagination(ENonDeterministicPaginationActions.first),
            arrow_type: 'stop',
            disabled: startPosition === 1,
          })}
        </li>
        <li className="flex items-center justify-center">
          {buttonTemplate({
            type: intl.formatMessage({ defaultMessage: 'previous', id: '7Gf6K9' }),
            dataType: 'previous',
            handleClick: () =>
              onNonDeterministicPagination(ENonDeterministicPaginationActions.prev),
            arrow_type: 'normal',
            disabled: startPosition === 1,
          })}
        </li>
        <li className="flex items-center justify-center">
          {buttonTemplate({
            type: intl.formatMessage({ defaultMessage: 'next', id: 'izd/bu' }),
            dataType: 'next',
            handleClick: () =>
              onNonDeterministicPagination(ENonDeterministicPaginationActions.next),
            arrow_type: 'normal',
            disabled: stopPosition === totalItems || isSearchLoading,
          })}
        </li>
      </ul>
    </nav>
  );
};

export default NonDeterministicPagination;
