import { usePagination } from '@material-ui/lab/Pagination';
import React from 'react';
import { useIntl } from 'react-intl';

import Button, {
  ButtonShape,
  ButtonSize,
  ButtonVariants,
} from '@/components/switchback/Button/Button';
import { ARROW_RIGHT } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';
import { getItemsPerPage } from '@/constants/pagination';

import css from './Pagination.module.css';

interface IPaginationProps {
  onPaginationChange: (page: number) => void;
  totalItems: number;
  itemsPerPage?: number;
  stopPosition: number;
  testIdPrefix: string;
}

interface IButton {
  type: 'previous' | 'next';
  page: number;
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  rest: any;
}

type IPaginationElement = React.HTMLAttributes<HTMLDivElement>;

const Pagination: React.FC<IPaginationProps & IPaginationElement> = ({
  itemsPerPage = getItemsPerPage(),
  onPaginationChange,
  totalItems,
  stopPosition,
  testIdPrefix,
  ...rest
}) => {
  const intl = useIntl();
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentPage = Math.ceil(stopPosition / itemsPerPage);

  const { items } = usePagination({
    count: totalPages,
    page: currentPage,
  });

  const buttonTemplate = ({ type, page, handleClick, rest }: IButton) => {
    return (
      <Button
        {...rest}
        aria-label={intl.formatMessage(
          {
            defaultMessage: 'Go to {type} page',
            id: 'j+6KoO',
            description: 'Pagination previous/next aria-label',
          },
          {
            type,
          },
        )}
        disabled={page < 1 || page > totalPages}
        className={`mr-4 last:mr-0 ${css.arrow}`}
        onClick={handleClick}
        shape={ButtonShape.circle}
        variant={ButtonVariants.whiteContained}
        size={ButtonSize.small}
        data-testid={`${testIdPrefix}-${type === 'previous' ? 'back' : 'forward'}-btn`}>
        <Icon
          data-icon={type}
          className={`${css.icon}`}
          name={ARROW_RIGHT}
          size={IconSize.normal}
        />
      </Button>
    );
  };

  if (totalPages <= 1) {
    return null;
  }

  return (
    <>
      <nav aria-label="pagination navigation" {...rest}>
        <ul className="flex content-center justify-center md:justify-start">
          {items.map(({ page, type, selected, onClick, ...item }, index) => {
            let children = null;

            const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
              onPaginationChange(page);
              onClick && onClick(e);
            };

            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
              children = (
                <button
                  disabled
                  className={`flex flex-col justify-center h-full px-3 md:px-4 ${css.button}`}>
                  …
                </button>
              );
            } else if (type === 'page') {
              children = (
                <button
                  aria-current={selected}
                  aria-label={intl.formatMessage(
                    {
                      defaultMessage: 'Go to page {page}',
                      id: 'ox3QYb',
                      description: 'Pagination go to aria-label',
                    },
                    {
                      page,
                    },
                  )}
                  className={`flex flex-col justify-center h-full px-3 md:px-4 ${css.button}`}
                  data-variant={selected ? 'current' : ''}
                  data-testid={`${testIdPrefix}-${page}-page${selected ? '-selected' : ''}`}
                  type="button"
                  onClick={handleClick}
                  {...item}>
                  {page}
                </button>
              );
            } else if (type === 'previous' || type === 'next') {
              children = buttonTemplate({ type, page, handleClick, rest: item });
            }

            return <li key={index}>{children}</li>;
          })}
        </ul>
      </nav>
    </>
  );
};

export default Pagination;
