import { Icon } from '@outdoorsyco/bonfire';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import ErrorBoundary from '@/components/ErrorBoundary';
import { EToastVariant, useToast } from '@/hooks/useToast';
import { trackGuidedSearchSelected } from '@/services/analytics/search';

import { GuidedSearchProvider, useGuidedSearchContext } from './GuidedSearchContext';
import { GuidedSearchModal } from './GuidedSearchModal';

type TGuidedSearchBannerProps = {
  bannerText: string;
  bannerCtaText: string;
  bannerStyle?: React.CSSProperties;
  bannerTextStyle?: React.CSSProperties;
  bannerCtaStyle?: React.CSSProperties;
  onClick?: () => void;
};

export const GuidedSearchBanner = ({
  bannerText,
  bannerCtaText,
  bannerStyle,
  bannerTextStyle,
  bannerCtaStyle,
  onClick,
}: TGuidedSearchBannerProps) => {
  const intl = useIntl();
  const { showToast } = useToast();

  const [showGuidedSearchModal, setShowGuidedSearchModal] = useState(false);

  return (
    <GuidedSearchProvider onApplySelection={() => setShowGuidedSearchModal(false)}>
      <div className="p-3 mb-5 rounded" style={bannerStyle}>
        <div className="flex justify-center text-base font-normal text-center whitespace-nowrap">
          <GuidedSearchBannerButton
            bannerText={bannerText}
            bannerCtaText={bannerCtaText}
            bannerTextStyle={bannerTextStyle}
            bannerCtaStyle={bannerCtaStyle}
            onClick={() => {
              setShowGuidedSearchModal(true);
              trackGuidedSearchSelected({ source: 'search_banner' });
              onClick?.();
            }}
          />
        </div>
      </div>

      <ErrorBoundary
        fallBackComponent={null}
        onComponentDidCatch={() => {
          setShowGuidedSearchModal(false);

          showToast(
            EToastVariant.Error,
            intl.formatMessage({ defaultMessage: 'Oops!', id: 'BEbOqj' }),
            intl.formatMessage({
              defaultMessage: 'Something went wrong, please try again.',
              id: '8GvVWZ',
            }),
          );
        }}>
        <GuidedSearchModal
          isOpen={showGuidedSearchModal}
          onClose={() => setShowGuidedSearchModal(false)}
          className="z-[1500]" // to cover header too
        />
      </ErrorBoundary>
    </GuidedSearchProvider>
  );
};

type TGuidedSearchBannerButtonProps = {
  bannerText: string;
  bannerCtaText: string;
  bannerTextStyle?: React.CSSProperties;
  bannerCtaStyle?: React.CSSProperties;
  onClick?: () => void;
};

const GuidedSearchBannerButton = ({
  bannerText,
  bannerCtaText,
  bannerTextStyle,
  bannerCtaStyle,
  onClick,
}: TGuidedSearchBannerButtonProps) => {
  const { reset: resetGuidedSearch } = useGuidedSearchContext();

  return (
    <button
      className="flex flex-col items-center justify-center md:flex-row"
      onClick={() => {
        resetGuidedSearch();
        onClick?.();
      }}>
      <div className="pr-1" style={bannerTextStyle}>
        {bannerText}
      </div>

      <div className="flex items-center justify-center font-bold" style={bannerCtaStyle}>
        {bannerCtaText}
        <Icon name="General.Caret.Right" className="inline-block h-6 ml-1 xl:h-4" />
      </div>
    </button>
  );
};
