import { Button, EButtonColorVariant, ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import Image from 'next/image';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import adTileImage from '@/assets/advertisement-tile/outdoorsy-stays-ad.png';
import {
  triggerDeliveryFilterFromAd,
  triggerStationaryDeliveryFilter,
} from '@/redux/modules/search';
import { trackPromoBannerClicked, trackPromoBannerViewed } from '@/services/analytics/promo';

export const AdvertisementTile = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const heading = intl.formatMessage({
    defaultMessage: 'Book your RV & campsite, we’ll have it set up when you arrive.',
    id: 'Y0OSQW',
  });

  const linkLabel = intl.formatMessage({ defaultMessage: 'See what’s nearby', id: 'MYZbVk' });

  const handleCLick = () => {
    trackPromoBannerClicked({
      bannerText: heading,
      bannerCTA: linkLabel,
      bannerLink: null,
      bannerName: 'outdoorsyStaysDeliveryMVP',
      source: 'search',
    });
    dispatch(triggerStationaryDeliveryFilter(true));
    dispatch(triggerDeliveryFilterFromAd(true));
  };

  useEffect(() => {
    trackPromoBannerViewed({
      bannerText: heading,
      bannerCTA: linkLabel,
      bannerLink: null,
      bannerName: 'outdoorsyStaysDeliveryMVP',
      source: 'search',
    });
  }, [heading, linkLabel]);

  return (
    <li className="relative">
      <div>
        <Image
          className="rounded-2xl"
          src={adTileImage}
          alt="Outdoorsy Stays advertisement image"
          role="presentation"
          layout="fill"
          objectFit="cover"
          width={0}
          height={0}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
      <div className="flex flex-col items-center justify-between h-full p-6 text-center text-white gap-y-2 min-h-[19rem] md:min-h-auto">
        <Text variant={ETextStyleVariant.LargeBold} className="z-10 md:max-w-[15rem]">
          {heading}
        </Text>
        <Button
          onClick={handleCLick}
          label={linkLabel}
          variant={EButtonColorVariant.Tertiary}
          onDark
        />
      </div>
    </li>
  );
};
