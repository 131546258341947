import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { isProdDeploy } from '@/utility/isSSR';
import { ICampgroundTile, IRentalTile } from '@/utility/mapSearchResultToTile';

const STAGING_CAMPER_RENTAL_ID = 143330;
const PROD_CAMPER_RENTAL_ID = 415674;

export const useStationaryCamperPromo = (rentals?: IRentalTile[] | ICampgroundTile[]) => {
  const isProd = isProdDeploy();
  const CAMPER_RENTAL_ID = isProd ? PROD_CAMPER_RENTAL_ID : STAGING_CAMPER_RENTAL_ID;
  const intl = useIntl();
  const STATIONARY_CAMPER_TILE_OVERRIDE_CONFIG = {
    id: CAMPER_RENTAL_ID,
    title: intl.formatMessage({
      defaultMessage: 'Your outdoor hotel! Book this camper at Outdoorsy Yosemite',
      id: 'pbC5yb',
    }),
  };

  // Check if the stationary camper is present within the first 6 rentals
  const hasStationaryCamperInFirstSix = useMemo(
    () =>
      rentals?.slice(0, 6).some(rental => rental.id === STATIONARY_CAMPER_TILE_OVERRIDE_CONFIG.id),
    [STATIONARY_CAMPER_TILE_OVERRIDE_CONFIG.id, rentals],
  );

  return {
    hasStationaryCamperInFirstSix,
    customRentalTitle: STATIONARY_CAMPER_TILE_OVERRIDE_CONFIG.title,
    stationaryCamperRentalId: STATIONARY_CAMPER_TILE_OVERRIDE_CONFIG.id,
  };
};
