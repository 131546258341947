import { EProjectSection, trackEventV2 } from '../trackEventV2';
import { EPromoBannerEventName, IPromoBannerEventInfo } from './types';

/**
 * TRACKING FUNCTIONS
 */
export function trackPromoBannerViewed(event: IPromoBannerEventInfo) {
  trackEventV2(EPromoBannerEventName.PROMO_BANNER_VIEWED, EProjectSection.MARKETPLACE, {
    ...event,
  });
}

export function trackPromoBannerClicked(event: IPromoBannerEventInfo) {
  trackEventV2(EPromoBannerEventName.PROMO_BANNER_CLICKED, EProjectSection.MARKETPLACE, {
    ...event,
  });
}
