import React, { createContext, useContext, useState } from 'react';

interface ISearchResultsContext {
  hoveredId: number | null;
  mapHoveredId: number | null;
  updateHoveredId: (id: number | null) => void;
  updateMapHoveredId: (id: number | null) => void;
}
const SearchResultsContext = createContext<ISearchResultsContext>({
  hoveredId: null,
  mapHoveredId: null,
  updateHoveredId: () => {
    /**/
  },
  updateMapHoveredId: () => {
    /**/
  },
});

export const useSearchResultsCtx = () => useContext(SearchResultsContext);

const SearchResultsCtx: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [hoveredId, setHoveredId] = useState<number | null>(null);
  const [mapHoveredId, setMapHoveredId] = useState<number | null>(null);

  return (
    <SearchResultsContext.Provider
      value={{
        hoveredId,
        mapHoveredId,
        updateHoveredId: setHoveredId,
        updateMapHoveredId: setMapHoveredId,
      }}>
      {children}
    </SearchResultsContext.Provider>
  );
};

export default SearchResultsCtx;
