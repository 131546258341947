import React from 'react';
import { useIntl } from 'react-intl';

import Heading from '@/components/switchback/Heading/Heading';
import { ERentalType } from '@/services/analytics/types';

type TNoResultsMessageProps = {
  hasNearby: boolean;
  isStay?: boolean;
  isCampground?: boolean;
};
const NoResultsMessage: React.FC<TNoResultsMessageProps> = ({
  hasNearby,
  isStay,
  isCampground,
}) => {
  const intl = useIntl();

  const title = intl.formatMessage(
    {
      defaultMessage:
        "Hmm, we don't have any {type, select, stay {stays} campground {campgrounds} other {listings}} here yet",
      id: 'tWcYzj',
    },
    { type: isStay ? ERentalType.STAY : isCampground ? ERentalType.CAMPGROUND : 'other' },
  );
  const description = hasNearby
    ? intl.formatMessage({
        defaultMessage: `Try changing your filters, moving the map to a new location, or checking out other travel options below.`,
        id: 'n/1ZCh',
      })
    : intl.formatMessage({
        defaultMessage: `Try changing your filters or moving the map to a new location.`,
        id: 'fWnhMG',
      });

  return (
    <div className="pt-6 text-base text-center rounded-lg md:mt-8 leading-7">
      <Heading level={2} className="text-2xl font-medium mb-2.5">
        {title}
      </Heading>
      <p>{description}</p>
    </div>
  );
};

export default NoResultsMessage;
