import React, { CSSProperties } from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';

const transitionStyles: Record<TransitionStatus, CSSProperties> = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 },
};

const Fade: React.FC<
  React.PropsWithChildren<{ in?: boolean; duration?: number; className?: string }>
> = ({ in: inProp = true, children, duration = 300, className }) => {
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  return (
    <Transition in={inProp} timeout={duration} appear unmountOnExit>
      {state => (
        <div
          className={className}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}>
          {children}
        </div>
      )}
    </Transition>
  );
};

export default Fade;
