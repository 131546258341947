import he from 'he';
import Head from 'next/head';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { PAGE_TITLE_SUFFIX } from '@/constants/pages';
import { getSearchCanonicalURL, getSearchTitle } from '@/redux/selectors/search/page';

const SearchHead: React.FC = () => {
  const title = useSelector(getSearchTitle);
  const canonicalURL = useSelector(getSearchCanonicalURL);

  const intl = useIntl();
  const description = intl.formatMessage({
    defaultMessage:
      'Safe, easy & fully insured. Schedule your RV rental from a huge local selection.',
    id: '62q4P1',
  });

  return (
    <Head>
      <title>{title ? `${he.decode(title)}${PAGE_TITLE_SUFFIX}` : 'Outdoorsy'}</title>

      <meta name="description" content={description} />
      <meta name="robots" content="noindex,follow" />

      <link rel="canonical" href={canonicalURL} />
    </Head>
  );
};

export default SearchHead;
