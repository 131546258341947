import { MapboxStyle } from '@/services/types/map';

import { ERentalType } from '../types';

export enum EMapEventName {
  MAP_VIEW_TOGGLED = 'Map View Toggled',
  LISTING_MARKER_PREVIEWED = 'Listing Marker Previewed',
  SEARCH_AS_MAP_MOVE_TOGGLED = 'Search As Map Move Toggled',
  SEARCH_MAP_EXPANDED = 'Search Map Expanded',
  BASE_MAP_UPDATED = 'Base Map Updated',
}

export interface IMapEventInfo {
  isEnabled: boolean;
}

export enum EMapStatus {
  EXPANDED = 'expanded',
  COLLAPSED = 'collapsed',
}

export interface IListingMarkerPreviewedEvent {
  rentalID: number;
  rentalType: ERentalType;
  isCampground: boolean;
  markersOnMap: number;
  isInstabook: boolean;
  pricePerNight: number;
  basePricePerNight: number;
  discountPercentOff: number;
  currentPageViewed: number;
  mapStatus: EMapStatus | null;
}

export interface IMapViewExpandedEvent {
  mapStatus: EMapStatus;
}

export interface IBaseMapUpdatedEvent {
  oldBase: MapboxStyle;
  newBase: MapboxStyle;
}
