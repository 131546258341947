import React from 'react';
import { useSelector } from 'react-redux';

import { getNearbyResultsMeta } from '@/redux/selectors/search/meta';
import { getIsLoading } from '@/redux/selectors/search/searchResults';

import { NearbyResultsCount } from './ResultsCount';

export const NearbyResultsCountContainer = () => {
  const { total } = useSelector(getNearbyResultsMeta);
  const isLoading = useSelector(getIsLoading);
  return isLoading ? null : <NearbyResultsCount total={total} />;
};
