import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';
import { IUserResponseData } from '@/services/types/search/users/id';

export const getOwnerName = createSelector<
  TRootState,
  IUserResponseData | undefined,
  string | undefined,
  {
    error?: boolean;
    name?: string;
  }
>(
  state => state.search.ownerInfo,
  state => state.search.errorOwnerInfo,
  (ownerInfo, error) => {
    if (error) {
      return { error: true };
    }

    return {
      name: ownerInfo?.attributes
        ? ownerInfo?.attributes?.business_name ||
          `${ownerInfo?.attributes?.first_name} ${ownerInfo?.attributes?.last_name}`
        : undefined,
    };
  },
);
